<template>
  <div>
    <v-autocomplete
      class="pt-2"
      v-model="storedCohorts"
      :items="cohorts"
      item-text="name"
      item-value="code"
      v-on:keyup="debouncedGetData"
      clearable
      dense
      hide-details
      multiple
      outlined
      :menu-props="{ maxHeight: '400', zIndex: 13 }"
      label="Cohortes Permutive"
    ></v-autocomplete>
  </div>
</template>

<script>
import debounce from "lodash/debounce";

import useAxios from "@/hooks/useAxios";

import { URL_PARAM_NAMES } from "@/utils/constants";
import {
  addQueryStringParam,
  synchronizeFilterWithQueryString,
} from "@/utils/http";

export default {
  name: "CohortFilter",
  data() {
    return {
      cohorts: [],
    };
  },
  setup() {
    const { axiosGet } = useAxios();

    return {
      axiosGet,
    };
  },
  async created() {
    await this.getData();

    await synchronizeFilterWithQueryString({
      router: this.$router,
      route: this.$route,
      store: this.$store,
      dispatcher: "regie/updatePermutiveCohorts",
      param: URL_PARAM_NAMES[this.$options.name],
      value: this.storedCohorts,
      is_multiple: true,
      is_integer: true,
      // is_boolean: false,
      // dependsOn: undefined,
    });
  },
  methods: {
    debouncedGetData: debounce(async function () {
      await this.getData();
    }, 500),
    async getData() {
      const { data } = await this.axiosGet("/regie/permutive_cohorts");

      this.cohorts = data;
    },
  },
  computed: {
    storedCohorts: {
      get() {
        return this.$store.getters["regie/getPermutiveCohorts"];
      },
      set(e) {
        this.$store.dispatch("regie/updatePermutiveCohorts", e);
      },
    },
  },
  watch: {
    storedCohorts(newValue) {
      addQueryStringParam(
        this.$router,
        this.$route,
        URL_PARAM_NAMES[this.$options.name],
        newValue
      );
    },
  },
};
</script>

<style></style>
