<template>
  <div>
    <v-autocomplete
      class="pt-2"
      v-model="storedPositions"
      :items="positions"
      item-text="name"
      item-value="id"
      :search-input.sync="searchText"
      v-on:keyup="debouncedGetData"
      clearable
      dense
      hide-details
      multiple
      outlined
      :menu-props="{ maxHeight: '400', zIndex: 13 }"
      label="GAM Positions"
    ></v-autocomplete>
  </div>
</template>

<script>
import debounce from "lodash/debounce";

import useAxios from "@/hooks/useAxios";

import { URL_PARAM_NAMES } from "@/utils/constants";
import {
  addQueryStringParam,
  synchronizeFilterWithQueryString,
} from "@/utils/http";

export default {
  name: "PositionFilter",
  data() {
    return {
      positions: [],
      searchText: "",
    };
  },
  setup() {
    const { axiosGet } = useAxios();

    return {
      axiosGet,
    };
  },
  async created() {
    await this.getData();

    await synchronizeFilterWithQueryString({
      router: this.$router,
      route: this.$route,
      store: this.$store,
      dispatcher: "regie/updateGamPositions",
      param: URL_PARAM_NAMES[this.$options.name],
      value: this.storedPositions,
      is_multiple: true,
      is_integer: true,
      // is_boolean: false,
      // dependsOn: undefined,
    });
  },
  methods: {
    debouncedGetData: debounce(async function () {
      await this.getData();
    }, 500),
    async getData() {
      const { data } = await this.axiosGet("/gam/positions", {
        search_value: this.searchText,
      });

      this.positions = data;
    },
  },
  computed: {
    storedPositions: {
      get() {
        return this.$store.getters["regie/getGamPositions"];
      },
      set(e) {
        this.$store.dispatch("regie/updateGamPositions", e);
      },
    },
  },
  watch: {
    storedPositions(newValue) {
      addQueryStringParam(
        this.$router,
        this.$route,
        URL_PARAM_NAMES[this.$options.name],
        newValue
      );
    },
  },
};
</script>

<style></style>
